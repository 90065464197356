import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Carousel, Button } from "react-bootstrap";
 
export default function Slider() {
  const [_] = useTranslation("global");
  const { lang } = useParams();
  const navigate = useNavigate();
  const headerImg = "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/header-img.png";
  const headerBonus = "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/header-bonus.png";
  const headerCart = "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/header-cart.png";
  const titleUnderline = "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/title-underline.png";
  const headerMan = "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/header-man.png";
  
  return (
    <header
      style={{
        textAlign: lang === "en" ? "left" : "right",
      }}
      dir={lang === "en" ? "ltr" : "rtl"}
      className="static-home-header position-relative"
      data-aos="fade-up"
    >
      <Carousel className="container h-full px-0" interval={5000}>
        {/* First Carousel Item */}
        <Carousel.Item>
          <div className="carousel-item carousel-item-md px-4 d-flex flex-column flex-md-row-reverse align-items-center justify-content-center">
            <div className="carousel-img-wrapper col-md-5">
              <img
                src={headerImg}
                alt="Ecompo Header"
                className="rounded carousel-img1"
                data-aos="fade-right"
              />
              <img
                src={headerBonus}
                alt="First Extra"
                className="position-absolute carousel-img2"
                data-aos="fade-left"
                data-aos-delay="200"
              />
              <img
                src={headerCart}
                alt="Second Extra"
                className="position-absolute carousel-img3"
                data-aos="fade-left"
                data-aos-delay="400"
              />
            </div>
            <div className="col-md-3 d-flex flex-column align-items-center align-items-md-start text-md-right">
              <h1
                className="ecombo-primary fw-bolder static-home-header-title my-2 mb-5"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                {_("static_home.slider.title")}
                <span className="position-relative">
                  <img
                    src={titleUnderline}
                    alt="Text underline"
                    className="position-absolute"
                    style={{
                      top: "100%",
                      left: lang === "en" ? "-180px" : "-35px",
                      width: "200px",
                    }}
                    data-aos="zoom-in"
                    data-aos-delay="600"
                  />
                </span>
              </h1>
              <p
                className="my-2 static-home-header-description"
                data-aos="fade-up"
              >
                {_("static_home.slider.description")}
              </p>
              <Button
                variant="secondary"
                className="btn-secondary-custom ecombo-btn-secondary my-3 px-5"
                data-aos="fade-up"
                data-aos-delay="500"
                onClick={() => navigate(`/${lang}/auth/login`)}
              >
                {_("static_home.slider.join_us")}
              </Button>
            </div>
          </div>
        </Carousel.Item>

        {/* Second Carousel Item */}
        <Carousel.Item>
          <div className="carousel-item carousel-item-md px-4 d-flex flex-column flex-md-row-reverse align-items-center justify-content-center">
            <div className="carousel-img-wrapper col-md-5 col-xl-4">
              <img
                src={headerMan}
                alt="Header Man"
                className="carousel-img4"
                data-aos="fade-right"
              />
            </div>
            <div className="col-md-3">
              <h1
                data-aos="fade-up"
                data-aos-delay="300"
                className="static-home-header-title"
              >
                <span className="fw-bolder ecombo-secondary">
                  {_("static_home.slider.subheader.title")}
                </span>
                <span className="fw-bolder ecombo-primary">
                  {_("static_home.slider.subheader.subtitle")}
                </span>
              </h1>
              <p className="static-home-header-description" data-aos="fade-up">
                {_("static_home.slider.subheader.description")}
              </p>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </header>
  );
}
