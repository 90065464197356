import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import { useParams } from "react-router-dom";

export default function MarketerSolution() {
  const [_] = useTranslation("global");
  const { lang } = useParams();
  const boxesImg =
    "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/boxes.png";
  const customerServiceImg =
    "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/customer-service.png";
  const deliveryTruckImg =
    "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/delivery-truck.png";
  const moneyBoxImg =
    "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/money-box.png";
  const moneyTransferImg =
    "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/money-transfer.png";

  const data = [
    {
      img: boxesImg,
      title: _("static_home.marketerSolution.storagePackaging.title"),
      text: _("static_home.marketerSolution.storagePackaging.text"),
    },
    {
      img: customerServiceImg,
      title: _("static_home.marketerSolution.customerService.title"),
      text: _("static_home.marketerSolution.customerService.text"),
    },
    {
      img: deliveryTruckImg,
      title: _("static_home.marketerSolution.fastShipping.title"),
      text: _("static_home.marketerSolution.fastShipping.text"),
    },
    {
      img: moneyBoxImg,
      title: _("static_home.marketerSolution.paymentCollection.title"),
      text: _("static_home.marketerSolution.paymentCollection.text"),
    },
    {
      img: moneyTransferImg,
      title: _("static_home.marketerSolution.moneyTransfer.title"),
      text: _("static_home.marketerSolution.moneyTransfer.text"),
    },
  ];

  return (
    <section
      dir={lang === "en" ? "ltr" : "rtl"}
      className="py-5 overflow-hidden"
      id="marketerSolution"
      data-aos="fade-up"
    >
      <div className="text-center mb-5" data-aos="fade-up" data-aos-delay={100}>
        <h2 className="ecombo-primary fw-bold">
          {_("static_home.marketerSolution.title")}
        </h2>
        <p>{_("static_home.marketerSolution.description")}</p>
      </div>

      <Container className="mt-5 rounded-lg">
        <Row className="justify-content-center">
          {data.map((item, idx) => (
            <Col
              key={idx + 1}
              xs={12}
              sm={6}
              md={4}
              xxl={3}
              className="d-flex justify-content-center mb-4"
            >
              <Card
                className="text-center p-4 border-0"
                data-aos="zoom-in"
                data-aos-delay={idx * 200}
              >
                <img
                  alt={item?.title}
                  src={item?.img}
                  width={150}
                  height={150}
                  className="mx-auto mb-3"
                  data-aos="fade-in"
                  data-aos-delay={idx * 200 + 100}
                />
                <h2
                  className="text-3xl font-normal text-text"
                  data-aos="fade-up"
                  data-aos-delay={idx * 200 + 200}
                >
                  {item?.title}
                </h2>
                <p
                  className="max-w-[250px] mx-auto"
                  data-aos="fade-up"
                  data-aos-delay={idx * 200 + 300}
                >
                  {item?.text}
                </p>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
