import React from "react";
import { Button, Row, Col, Container, Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function About() {
  const { lang } = useParams();
  const navigate = useNavigate();
  const [_] = useTranslation("global");
  const supplier = "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/supplier.png";
  const marketerImg = "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/marketer.png";
  
  return (
    <section
      dir={lang === "en" ? "ltr" : "rtl"}
      className="py-5 bg-light"
      id="about"
      data-aos="fade-up"
    >
        
      <Container>
        <div className="text-center mb-5">
          <h2 className="ecombo-primary fw-bold">
            {_("static_home.about.title")}
          </h2>
          <p className="mt-3 mx-auto" style={{ maxWidth: "800px" }}>
            {_("static_home.about.description")}
          </p>
        </div>

        <Row className="align-items-center justify-content-between">
          <Col
            md={6}
            className="d-flex justify-content-center mb-4 mb-md-0"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <Card
              className="text-center border-0 p-4 shadow-sm"
              style={{ backgroundColor: "#59C6C91C" }}
            >
              <Card.Img
                variant="top"
                src={supplier}
                alt={_("static_home.about.supplier")}
                style={{ width: "80px", height: "80px", margin: "0 auto" }}
                data-aos="zoom-in"
                data-aos-delay="300"
              />
              <Card.Body>
                <Card.Title
                  className="ecombo-secondary fs-3"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  {_("static_home.about.supplier")}
                </Card.Title>
                <Card.Text
                  className="text-muted fs-5"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  {_("static_home.about.supplierDescription")}
                </Card.Text>
                <Button
                  onClick={() => navigate(`/${lang}/auth/register/seller`)}
                  variant="secondary"
                  className="mt-2 border-0"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  {_("static_home.about.registerAsSupplier")}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col
            md={6}
            className="d-flex justify-content-center"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            <Card
              className="text-center border-0 p-4 shadow-sm"
              style={{ backgroundColor: "#F3A8371C" }}
            >
              <Card.Img
                variant="top"
                src={marketerImg}
                alt={_("static_home.about.marketer")}
                style={{ width: "80px", height: "80px", margin: "0 auto" }}
                data-aos="zoom-in"
                data-aos-delay="300"
              />
              <Card.Body>
                <Card.Title
                  className="ecombo-secondary fs-3"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  {_("static_home.about.marketer")}
                </Card.Title>
                <Card.Text
                  className="text-muted fs-5"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  {_("static_home.about.marketerDescription")}
                </Card.Text>
                <Button
                  onClick={() => navigate(`/${lang}/auth/register/marketer`)}
                  variant="secondary"
                  className="mt-2 border-0"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  {_("static_home.about.registerAsMarketer")}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
